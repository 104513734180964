import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41c4601d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "modal"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QrcodeStream = _resolveComponent("QrcodeStream")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleQrReader && _ctx.toggleQrReader(...args)))
        }, "QR-kód leolvasása"),
        (_ctx.showQrReader)
          ? (_openBlock(), _createBlock(_component_QrcodeStream, {
              key: 0,
              onDecode: _ctx.onDecode
            }, null, 8, ["onDecode"]))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProducts, (product) => {
        return (_openBlock(), _createElementBlock("div", {
          key: product.id
        }, [
          _createElementVNode("h2", null, _toDisplayString(product.title), 1),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterOfferings(product.allowed_offering_types), (offering) => {
              return (_openBlock(), _createElementBlock("li", {
                key: offering.key
              }, _toDisplayString(offering.title) + ": " + _toDisplayString(offering.available_quantity) + " db ", 1))
            }), 128))
          ]),
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.fetchProductDetails(product.id))
          }, "Részletek", 8, _hoisted_1)
        ]))
      }), 128)),
      (_ctx.productDetails && _ctx.productDetails.inventory_item)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.productDetails.inventory_item.title), 1),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productDetails.devices, (device) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: device.id
                }, _toDisplayString(device.description) + " - " + _toDisplayString(device.codentify), 1))
              }), 128))
            ]),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.productDetails = null))
            }, "Bezár")
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.matchedDevice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, "Termék: " + _toDisplayString(_ctx.matchedDevice.inventory_item.title), 1),
          _createElementVNode("p", null, "Kód: " + _toDisplayString(_ctx.matchedDevice.codentify), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}