
import { ref, defineComponent, onMounted, computed } from 'vue';
import axios from 'axios';
import { Product, OfferingType } from '../models';
import { QrcodeStream } from "vue-qrcode-reader";
const apiInstance = axios.create({
  baseURL: 'https://appli.soultrade.hu/mannedapiv2/'
});

export default defineComponent({
  name: 'InventoryList',
  components: {
    QrcodeStream
  },
  setup() {
    const products = ref<Product[]>([]);
    const productDetails = ref<any>(null);
    const showQrReader = ref(false);

    const filteredProducts = computed(() => {
      return products.value.filter(product => filterOfferings(product.allowed_offering_types).length > 0);
    });

    const filterOfferings = (offerings: OfferingType[]) => {
      const allowedTitles = [
        'Eladhat\u00f3 eszk\u00f6z', 
        'Csere eszk\u00f6z', 
        'Eladhat\u00f3 kieg\u00e9sz\u00edt\u0151'
      ];
      return offerings.filter(o => allowedTitles.includes(o.title) && o.available_quantity > 0);
    };

    const requestCameraAccess = async (): Promise<boolean> => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // Ha sikerült hozzáférni, zárjuk be a streamet
        stream.getTracks().forEach(track => track.stop());
        return true; // hozzáférés engedélyezve
      } catch (error) {
        console.error('Hozzáférés elutasítva vagy hiba lépett fel:', error);
        return false; // hozzáférés elutasítva vagy hiba
      }
    };

    const toggleQrReader = async () => {
      if (!showQrReader.value) {
        // Ha a QR olvasó nincs aktív, kérjünk engedélyt előtte
        const accessGranted = await requestCameraAccess();
        if (!accessGranted) {
          console.log("Kamera hozzáférés szükséges a QR-kód olvasásához.");
          return; // Ne indítsa el a QR-kód olvasót, ha nincs engedély
        }
      }
      showQrReader.value = !showQrReader.value;
    };

    const onDecode = async (content: string) => {
      for (let product of products.value) {
        await fetchProductDetails(product.id);
        const deviceWithMatchingCodentify = productDetails.value.devices.find((device: any) => device.codentify === content);
        if (deviceWithMatchingCodentify) {
          productDetails.value = { inventory_item: { title: deviceWithMatchingCodentify.description }, devices: [deviceWithMatchingCodentify] };
          break; // Megtaláltuk a megfelelő terméket, nincs szükség további keresésre
        }
      }
    };

    const fetchProductDetails = async (id: number) => {
      try {
        const response = await apiInstance.get(`/v1/warehouse/208/inventory_item/${id}/devices?_mat=vb8HYCqOU5j56Ktdu8JmMElFSH0BAfZU820NXPqXgBA_&viewer_lang_id=1&page=1&items_per_page=10`);
        if (response.data && response.data.data && response.data.data.inventory_item) {
          productDetails.value = response.data.data;
        } else {
          console.error('Nem várt válasz formátum:', response.data);
        }
      } catch (error) {
        console.error("Hiba a termék részleteinek lekérése közben:", error);
      }
    };

    onMounted(async () => {
      try {
        const response = await apiInstance.get('/v1/warehouse/208/stocks?_mat=fiRQB9XqyL5XRbo8ZCNjLm3RjUAOZEiaXpWlhb0hiN0_&viewer_lang_id=1');
        products.value = response.data.data.stock.inventory_items;
      } catch (error) {
        console.error("Hiba az adatok lekérése közben:", error);
      }
    });

    return {
      filteredProducts,
      filterOfferings,
      fetchProductDetails,
      productDetails,
      toggleQrReader,
      showQrReader
    };
  }
});
