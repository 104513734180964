
import { defineComponent } from 'vue';
import InventoryList from './components/InventoryList.vue';

export default defineComponent({
  name: 'App',
  components: {
    InventoryList
  }
});
